import { SVGElement } from '@/types/utilityTypes';

import { ReactComponent as APIIcon } from '../../assets/icons/menu/API.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/menu/Home.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/menu/Project.svg';

export type Language = 'ko' | 'en' | 'ja';
export const languageList = ['ko', 'en', 'ja'] as Language[];
export const defaultLanguage = languageList[0] as Language;
export const SPEECH_CONTROL_LIST = [
  {
    id: 'pitch_shift',
    title: 'Pitch Shift',
    min: -24,
    max: 24,
    step: 1,
    defaultValue: 0,
  },
  {
    id: 'pitch_variance',
    title: 'Pitch Variance',
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
  {
    id: 'speed',
    title: 'Speed',
    value: 1,
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
];
export const DEFAULT_PARAMETER = {
  pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
  pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
  speed: SPEECH_CONTROL_LIST[2].defaultValue,
};
export type QuickMenuType = 'Home' | 'Projects' | 'Voice Cloning' | 'API';
export type QuickMapType = {
  name: QuickMenuType;
  icon: SVGElement;
};
// locale 적용시 변경 필요
export const QUICK_MENU: {
  internal: QuickMapType[];
  external: QuickMapType[];
} = {
  internal: [
    { name: 'Home', icon: HomeIcon },
    { name: 'Projects', icon: ProjectIcon },
  ],
  external: [
    // { name: 'Voice Cloning', icon: VoiceCloningIcon },
    { name: 'API', icon: APIIcon },
  ],
};
export const MAX_LINE_TEXT_COUNT = 200;
export const PERMISSION_ERROR_CODE = 403;
export const DISCORD_URL = 'https://discord.com/invite/M3h5GJzQbG';
export const ZENDESK_URL =
  'https://support.supertone.ai/hc/en-us/requests/new?ticket_form_id=9564913319183';

export const TOP_BANNER_LINK_URL = 'https://web.supertone.ai/v';

export const TERMS_OF_SERVICES_URL =
  'https://supertone.notion.site/Play-Terms-of-Service-121bc844cf5681fb8808cc7359cf5380?pvs=4';
export const MAIN_WEB_URL = 'https://www.supertone.ai/play';
export const PRIVACY_POLICY_URL =
  'https://supertone.notion.site/Privacy-Policy-2024-09-04-6eacb9c77aa54d28a0600fe8f6389296?pvs=4';

export const VOICE_CLONNING_FORM_URL = 'https://forms.gle/ZRSV7rPXVtY2gkJb6';
export const WHY_NOT_PAY_FORM_URL =
  'https://docs.google.com/forms/d/1ishmV1IRgRaqfVtGRgOnPKwkz0-OxwfvbWyUqtibXYY/edit?ts=67469cee';

export const SUPERTONE_API_URL = 'https://console.supertoneapi.com/';
export const HOME_BANNER_LINK =
  'https://supertone.notion.site/2-176bc844cf56801e9442ce5cc46fb44e';

export const SUPERTONE_YOUTUBE_URL = 'https://www.youtube.com/@supertone_ai';

export const SUPERTONE_INSTAGRAM_URL =
  'https://www.instagram.com/supertoneplay';

export const SUPERTONE_TICTOK_URL = 'https://www.tiktok.com/@supertoneplay';

export const MEME_VOICE = '[Meme]';
export const DEFAULT_VOICE = '[Default]';
export const UTM_SESSION_STORAGE_KEY = 'SUP_UTM';
export const GA_TRACKING_ID = 'AW-16647606337';
export const GA_CONVERSION_AD_ID = 'AW-16647606337/Vz1rCJql7ZEaEMGgmYI-';
