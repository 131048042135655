import IconButton from '@/components/Button/IconButton';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as DeleteIcon } from '../assets/icons/DeleteIcon.svg';
import { isShowLineBannerAtom } from '../stores/atoms/ui';

const LineBanner = () => {
  const setisShowLineBanner = useSetRecoilState(isShowLineBannerAtom);
  return (
    <section className="line-banner">
      <section className="line-banner-text">
        🚧그랜드 오픈을 위해 2월 18일 오전 6시부터 9시까지 서비스가 잠시
        중단됩니다.🚧
      </section>
      <IconButton
        className="btn-exit"
        onClick={() => setisShowLineBanner(false)}
      >
        <DeleteIcon />
      </IconButton>
    </section>
  );
};
export default LineBanner;
