import { ReactComponent as CloseIcon } from '@/assets/icons/CloseIcon.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/EditIcon.svg';
import { ReactComponent as PauseIcon } from '@/assets/icons/Pause.svg';
import { ReactComponent as PlayRoundedIcon } from '@/assets/icons/PlayRoundedIcon.svg';
import CircularProgress from '@/assets/lottie/CircularProgress.json';
import HomeSpeaking from '@/assets/lottie/HomeSpeaking.json';
import SupButton from '@/components/Button/SupButton';
import { ProfileAvatar } from '@/components/ProfileAvatar/ProfileAvatar';
import { LottieRefCurrentProps } from 'lottie-react';
import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DesktopView({
  isEditable,
  isPlaying,
  lottieRef,
  script,
  isEditing,
  editedText,
  setEditedText,
  handleClose,
  handleSubmit,
  onClickPlay,
  setIsEditing,
  thumbnailPath,
  isLoading,
  circularProgressLottieRef,
  onPlayPause,
  resetAudio,
  isAudioEnded,
  handleEditClick,
}: {
  isEditable: boolean;
  isPlaying: boolean;
  lottieRef: React.RefObject<LottieRefCurrentProps>;
  script: string;
  isEditing: boolean;
  editedText: string;
  setEditedText: (text: string) => void;
  handleClose: () => void;
  handleSubmit: () => void;
  onClickPlay: () => void;
  setIsEditing: (isEditing: boolean) => void;
  thumbnailPath: string;
  isLoading: boolean;
  circularProgressLottieRef: React.RefObject<LottieRefCurrentProps>;
  onPlayPause: () => void;
  resetAudio: () => void;
  isAudioEnded: boolean;
  handleEditClick: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="desktop">
      <div className="item-container">
        <div className="script-container">
          <div className="script-container-left">
            <div className="relative">
              <ProfileAvatar
                src={thumbnailPath}
                alt="avatar picture"
                size={56}
              />
              {isPlaying && (
                <div className="playing-container">
                  <Lottie
                    lottieRef={lottieRef}
                    animationData={HomeSpeaking}
                    loop={true}
                    onComplete={() => lottieRef.current?.stop()}
                    className="playing"
                  />
                </div>
              )}
            </div>
            {isEditing || <div className="script">{script}</div>}
          </div>
          {isEditing && (
            <textarea
              className="script primary-script"
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              autoFocus
              maxLength={100}
              rows={1}
              disabled={!isEditable}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = 'auto';
                target.style.height = `${target.scrollHeight}px`;
              }}
              placeholder={t('home.placeholder') || ''}
            />
          )}
          <div className="buttons-container">
            {isEditing ? (
              <>
                <SupButton
                  color="grey"
                  width="58px"
                  height="58px"
                  borderRadius="50%"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleClose();
                  }}
                >
                  <div className="flex-center">
                    <CloseIcon />
                  </div>
                </SupButton>
                <SupButton
                  color={editedText.length > 0 ? 'white' : 'grey'}
                  width="58px"
                  height="58px"
                  borderRadius="50%"
                  onClick={isAudioEnded ? handleSubmit : onPlayPause}
                  disabled={editedText.length === 0}
                >
                  <div className="flex-center">
                    {isLoading ? (
                      <div className="circular-progress-container">
                        <Lottie
                          lottieRef={circularProgressLottieRef}
                          animationData={CircularProgress}
                          loop={true}
                          className="circular-progress"
                        />
                      </div>
                    ) : isPlaying ? (
                      <PauseIcon />
                    ) : (
                      <PlayRoundedIcon />
                    )}
                  </div>
                </SupButton>
              </>
            ) : (
              <>
                <div className="hover-button">
                  <SupButton
                    color="grey"
                    width="58px"
                    height="58px"
                    borderRadius="50%"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setIsEditing(true);
                      resetAudio();
                      handleEditClick();
                    }}
                  >
                    <div className="flex-center">
                      <EditIcon />
                    </div>
                  </SupButton>
                  <div className="hover-button-tooltip">
                    {t('home.editHover')}
                  </div>
                </div>
                <SupButton
                  color="grey"
                  width="58px"
                  height="58px"
                  borderRadius="50%"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    !isPlaying && onClickPlay();
                  }}
                >
                  <div className="flex-center">
                    {isPlaying ? <PauseIcon /> : <PlayRoundedIcon />}
                  </div>
                </SupButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
