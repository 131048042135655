import { Grey, White } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useLog } from '../../hooks/useLog/useLog';
import { HEADER_HEIGHT, selectedQuickMenuAtom } from '../../stores/atoms/ui';
import {
  QUICK_MENU,
  QuickMenuType,
  SUPERTONE_API_URL,
} from '../../stores/data/config';
import MenuListItem from './MenuListItem';

const StyledMenu = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  width: 7.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5625rem;
  padding: 1rem;
  background-color: ${Grey[700]};
  align-items: center;
  z-index: 2;
  &.expanded {
    height: calc(100vh - ${HEADER_HEIGHT * 2}rem);
  }
  .divider {
    width: 80%;
    border-bottom: 1px solid ${Grey[500]};
  }
  .nav-menu {
    display: flex;
    width: 5.825rem;
    padding: 1rem 0;
    flex-direction: column;
    border-radius: 0.25rem;
    gap: 1rem;
    &-item {
      flex-direction: column;
      gap: 1rem;
      color: ${Grey[500]};
      width: 100%;
      height: 5.825rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      cursor: pointer;
      &.voice-cloning {
        height: 8rem;
      }
      &.selected {
        color: ${White};
      }
      &.project {
        margin-top: 1rem;
        border-radius: 0.25rem;
        width: 5.625rem;
        height: 5.625rem;
      }
      .title {
        font-size: 1rem;
        font-weight: ${FontWeight.SemiBold};
        text-align: center;
      }
      &:active,
      &:hover {
        background-color: ${Grey[600]};
        border-radius: 0.4rem;
      }
    }
  }
`;

const QuickMenu = () => {
  const [selectedQuickMenu, setSelectedQuickMenu] = useRecoilState(
    selectedQuickMenuAtom
  );
  const navigate = useNavigate();

  const { track } = useLog();
  const handleQuickMenuClick = useCallback(
    (name: QuickMenuType) => {
      if (name === 'Home') {
        track('OPEN_HOME_TAB');
        navigate('/');
      } else if (name === 'Projects') {
        track('OPEN_PROJECT_TAB');
        navigate('/projects');
      } else if (name === 'Voice Cloning') {
        track('OPEN_VOICE_CLONING_TAB');
        window.open(SUPERTONE_API_URL, '_blank')?.focus();
        return;
      } else if (name === 'API') {
        track('OPEN_API_TAB');
        window.open(SUPERTONE_API_URL, '_blank')?.focus();
        return;
      }
      setSelectedQuickMenu(name);
    },
    [setSelectedQuickMenu, navigate, track]
  );

  return (
    <StyledMenu className="gnb-menu">
      <ul className="nav-menu">
        {QUICK_MENU.internal.map(({ name, icon: Icon }) => {
          return (
            <MenuListItem
              key={`quick-menu-${name}`}
              title={name}
              Icon={Icon}
              isSelected={selectedQuickMenu === name}
              onClick={() => handleQuickMenuClick(name)}
            />
          );
        })}
      </ul>
      <div className="divider" />
      <ul className="nav-menu">
        {QUICK_MENU.external.map(({ name, icon: Icon }) => {
          return (
            <MenuListItem
              key={`quick-menu-${name}`}
              title={name}
              Icon={Icon}
              isSelected={selectedQuickMenu === name}
              onClick={() => handleQuickMenuClick(name)}
            />
          );
        })}
      </ul>
    </StyledMenu>
  );
};
export default QuickMenu;
