import { ReactComponent as CloseIcon } from '@/assets/icons/CloseIcon.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/EditIcon.svg';
import { ReactComponent as PauseIcon } from '@/assets/icons/Pause.svg';
import { ReactComponent as PlayRoundedIcon } from '@/assets/icons/PlayRoundedIcon.svg';
import CircularProgress from '@/assets/lottie/CircularProgress.json';
import HomeSpeaking from '@/assets/lottie/HomeSpeaking.json';
import SupButton from '@/components/Button/SupButton';
import { ProfileAvatar } from '@/components/ProfileAvatar/ProfileAvatar';
import { LottieRefCurrentProps } from 'lottie-react';
import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function MobileView({
  isEditable,
  isEditing,
  editedText,
  setEditedText,
  script,
  isPlaying,
  lottieRef,
  handleClose,
  handleSubmit,
  onClickPlay,
  setIsEditing,
  thumbnailPath,
  isLoading,
  circularProgressLottieRef,
  onPlayPause,
  resetAudio,
  isAudioEnded,
  handleEditClick,
}: {
  isEditable: boolean;
  isEditing: boolean;
  editedText: string;
  setEditedText: (text: string) => void;
  script: string;
  isPlaying: boolean;
  lottieRef: React.RefObject<LottieRefCurrentProps>;
  handleClose: () => void;
  handleSubmit: () => void;
  onClickPlay: () => void;
  setIsEditing: (isEditing: boolean) => void;
  thumbnailPath: string;
  isLoading: boolean;
  circularProgressLottieRef: React.RefObject<LottieRefCurrentProps>;
  onPlayPause: () => void;
  resetAudio: () => void;
  isAudioEnded: boolean;
  handleEditClick: () => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="mobile">
      <div className="item-container">
        <div className="script-container">
          {isEditing ? (
            <textarea
              className="script primary-script mobile-input-textarea"
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              disabled={!isEditable}
              autoFocus
              maxLength={100}
              placeholder={t('home.placeholder') || ''}
            />
          ) : (
            <div className="script">{script}</div>
          )}
          <div className="relative mobile-bottom-container">
            <ProfileAvatar src={thumbnailPath} alt="avatar picture" size={58} />
            {isPlaying && (
              <div className="playing-container">
                <Lottie
                  lottieRef={lottieRef}
                  animationData={HomeSpeaking}
                  loop={true}
                  onComplete={() => lottieRef.current?.stop()}
                  className="playing"
                />
              </div>
            )}
            <div className="buttons-container">
              {isEditing ? (
                <>
                  <SupButton
                    color="grey"
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      handleClose();
                    }}
                  >
                    <div className="flex-center">
                      <CloseIcon width={13} height={13} />
                    </div>
                  </SupButton>
                  <SupButton
                    color={editedText.length > 0 ? 'white' : 'grey'}
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    onClick={isAudioEnded ? handleSubmit : onPlayPause}
                    disabled={editedText.length === 0}
                  >
                    <div className="flex-center">
                      {isLoading ? (
                        <div className="circular-progress-container">
                          <Lottie
                            lottieRef={circularProgressLottieRef}
                            animationData={CircularProgress}
                            loop={true}
                            className="circular-progress"
                          />
                        </div>
                      ) : isPlaying ? (
                        <PauseIcon width={13} height={13} />
                      ) : (
                        <PlayRoundedIcon width={13} height={13} />
                      )}
                    </div>
                  </SupButton>
                </>
              ) : (
                <>
                  <SupButton
                    color="grey"
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setIsEditing(true);
                      resetAudio();
                      handleEditClick();
                    }}
                  >
                    <div className="flex-center">
                      <EditIcon width={13} height={13} />
                    </div>
                  </SupButton>
                  <SupButton
                    color="grey"
                    width="32px"
                    height="32px"
                    borderRadius="50%"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      !isPlaying && onClickPlay();
                    }}
                  >
                    <div className="flex-center">
                      {isPlaying ? (
                        <PauseIcon width={17} height={17} />
                      ) : (
                        <PlayRoundedIcon width={13} height={13} />
                      )}
                    </div>
                  </SupButton>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
