import {
  MOBILE_BREAKPOINT,
  SMALL_TABLET_BREAKPOINT,
} from '@/consts/breakpoints';
import { Black, Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from '../stores/atoms/ui';

export const StyledCommonLayout = styled.section`
  display: flex;
  .gnb-menu {
    border-right: 0.5px solid ${Grey[500]};
    margin-top: ${HEADER_HEIGHT}rem;
    min-height: calc(100vh - ${HEADER_HEIGHT}rem);

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      display: none;
    }
  }
  .layout-header {
    background-color: ${Black};
    height: ${HEADER_HEIGHT}rem;
    border-bottom: 1px solid ${Grey[450]};
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Pretendard Variable';
    z-index: 1000;
    padding: 0 46px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      height: ${HEADER_HEIGHT_MOBILE}rem;
    }

    .navbar-left {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        position: static;
        transform: none;
        justify-content: flex-start;
      }

      .header-logo {
        position: relative;
        display: flex;
        align-items: center;

        .header-logo-svg {
          width: 187px;

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            width: 139px;
          }
        }

        .logo-linkout-svg {
          width: 18px;
          height: 18px;

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            width: 14px;
            height: 14px;
          }
        }

        .header-logo-tooltip {
          position: absolute;
          font-size: 16px;
          color: ${Grey[150]};
          font-weight: 400;
          line-height: 150%;
          background-color: ${Black};
          top: 40px;
          left: 80px;
          width: 300px;
          padding: 8px 12px;
          border-radius: 6px;
          display: none;
        }

        .logo-linkout {
          border-radius: 6px;
          border: 1px solid ${Grey[500]};
          padding: 3px;
          margin-left: 8px;
          cursor: pointer;

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            margin-left: 4px;
            padding: 2px;
          }

          @media (min-width: ${MOBILE_BREAKPOINT}px) {
            &:hover {
              .header-logo-tooltip {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
    .navbar-right {
      display: flex;
      gap: 8px;
      margin-left: auto;

      @media (max-width: ${SMALL_TABLET_BREAKPOINT}px) {
        padding-right: 0;
      }

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        padding-right: 0;
      }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  .layout-content {
    margin-top: ${HEADER_HEIGHT}rem;
    margin-left: 7.5rem;
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 7.5rem);

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-top: ${HEADER_HEIGHT_MOBILE}rem;
      margin-left: 0;
    }

    .sp-middle-area {
      height: calc(100vh - ${HEADER_HEIGHT}rem);
    }
  }

  // footer
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: ${SMALL_TABLET_BREAKPOINT}px) {
    .desktop-footer {
      display: none;
    }
    .mobile-footer {
      width: 100%;
      height: 229px;
      display: flex;
      background-color: ${Grey[700]};
    }
  }

  @media (min-width: ${SMALL_TABLET_BREAKPOINT}px) {
    .desktop-footer {
      width: 100%;
      display: flex;
      background-color: ${Grey[700]};
      margin-top: auto;
    }
    .mobile-footer {
      display: none;
    }
  }
`;
