import { SVGElement } from '@/types/utilityTypes';
import classNames from 'classnames';

interface Props {
  title: string;
  Icon: SVGElement;
  isSelected: boolean;
  onClick: () => void;
}
const MenuListItem = ({ title, Icon, isSelected, onClick }: Props) => (
  <li
    className={classNames('nav-menu-item', isSelected && 'selected')}
    onClick={onClick}
  >
    <Icon />
    <span className="title">{title}</span>
  </li>
);

export default MenuListItem;
