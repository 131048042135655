import { ReactComponent as AddIcon } from '@/pages/screenplay/assets/icons/project/AddIcon.svg';
import CommonLayout from '@/pages/screenplay/components/CommonLayout';
import useProjects from '@/pages/screenplay/hooks/useProjects';
import { selectedQuickMenuAtom } from '@/pages/screenplay/stores/atoms/ui';
import { defaultLanguage } from '@/pages/screenplay/stores/data/config';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { useLog } from '../hooks/useLog/useLog';
import ProjectItem from './ProjectItem';
import StyledProjects from './StyledProjects';

const ProjectList = () => {
  const { t } = useTranslation();
  const { track } = useLog();
  const { voiceProfileList } = useVoiceLibraryQuery();
  const navigate = useNavigate();
  const setSelectedQuickMenu = useSetRecoilState(selectedQuickMenuAtom);
  const { removeProject, addProject, updateProject, projectList } =
    useProjects();

  const deleteProject = useCallback(
    async (id: string) => {
      await removeProject(id);
    },
    [removeProject]
  );

  const createProject = useCallback(async () => {
    const defaultVoiceProfile = voiceProfileList?.[0].id;
    const project = await addProject({
      name: 'New Project',
      language: defaultLanguage,
      voiceIds: defaultVoiceProfile ? [defaultVoiceProfile] : [],
    });

    track('ADD_NEW_PROJECT', { projectId: project.id });
    navigate(`/${project.id}/editor`);
  }, [addProject, navigate, track, voiceProfileList]);

  useEffect(() => {
    setSelectedQuickMenu('Projects');
  }, [setSelectedQuickMenu]);

  return (
    <StyledProjects className="projects">
      <section className="project-wrap">
        <section className="project-title">
          <h2>{t('Projects')}</h2>
        </section>
        <section className="project-contents">
          <ul className="project-list">
            <li
              className="project-list-item create-new"
              onClick={createProject}
            >
              <section className="btn-create-new">
                {t('Create New Project')}
              </section>
              <section className="icon-create-new">
                <AddIcon />
              </section>
            </li>
            {[...(projectList ?? [])]
              .sort(
                (
                  { updatedAt: prevUpdatedAt },
                  { updatedAt: nextUpdatedAt }
                ) => {
                  if (nextUpdatedAt && prevUpdatedAt) {
                    return (
                      new Date(nextUpdatedAt).getTime() -
                      new Date(prevUpdatedAt).getTime()
                    );
                  }
                  return 0;
                }
              )
              .map((project) => (
                <ProjectItem
                  key={project.id}
                  project={project}
                  onDelete={() => deleteProject(project.id)}
                  onChangeName={(id, name) => updateProject({ id, name })}
                />
              ))}
          </ul>
        </section>
      </section>
    </StyledProjects>
  );
};

const Projects = () => {
  return (
    <CommonLayout>
      <ProjectList />
    </CommonLayout>
  );
};
export default Projects;
